
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {TABLE_FOOTER_OPTIONS} from "@/misc/TableFooterOptions";
import {EventStatus} from "@/enum/EventStatus.enum";
import Employee from "@/models/Employee";

@Component
export default class EmployeeListComponent extends Vue {
  @Prop({ required: true })
  public employees!: Employee[];

  @Prop({ required: true })
  private onListItemClicked!: (item: Employee) => void;

  @Prop({required: true})
  private onListItemDelete!: (item: Employee) => void;

  @Prop({ default: false })
  private isLoading!: boolean;

  @Prop({ default: '' })
  private search!: string;

  /**
   * headers of the table
   */
  private headers = [
    { text: this.$t('GENERAL.USER_NAME'), align: 'start', sortable: true, value: 'account.username' },
    { text: this.$t('GENERAL.FIRST_NAME'), align: 'start', sortable: true, value: 'firstName' },
    { text: this.$t('GENERAL.LAST_NAME'), align: 'start', sortable: true, value: 'lastName'},
    { text: this.$t('GENERAL.EMAIL'), align: 'start', sortable: true, value: 'account.email' },
    { text: this.$t('GENERAL.ACTIONS'), align: 'start', sortable: false, value: '' },
  ];

  /**
   * options for the footer
   * @private
   */
  private footerOptions = TABLE_FOOTER_OPTIONS;

  /**
   * translates the date with the custom translater plugin, checks if the date is set to avoid 'invalid dateTime's in
   * the table
   * @param date
   * @private
   */
  private translateDate(date?: string): string {
    // if date doesn't exist, just return placeholder
    if(!date) return '-';

    // return the translated date
    return this.$formatDateFromString(date);
  }

  /**
   * translates the status based on the passed status
   * @param status
   * @private
   */
  private translateStatus(status?: EventStatus) {
    // if status doesn't exist, just return placeholder
    if(!status) return '-';

    // returns translated status
    return this.$t(`EVENT_STATUS.${status}`);
  }
}
