import i18n from "@/i18n";

/**
 * options for the table footers (e.g. container list, penalty payment list etc.)
 */
export const TABLE_FOOTER_OPTIONS = {
    'items-per-page-options': [10, 25, 100],
    'items-per-page-text': i18n.t('GENERAL.ROWS_PER_PAGE'),
    'page-text': `{0}-{1} ${i18n.t('GENERAL.OF')} {2}`,
};
